<template>
  <div id="assigned-classes" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table id="list" :data="assignedClasses" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">
          {{ assignedClasses.indexOf(props.row) + 1 }}
        </b-table-column>

        <b-table-column label="School Class" v-slot="props">
          {{ props.row.schoolClass.name }}
        </b-table-column>

        <b-table-column label="Number Of Students" v-slot="props">
          {{ props.row.numberOfStudents }}
        </b-table-column>

        <b-table-column label="Number Of Subjects" v-slot="props">
          {{ props.row.numberOfSubjects }}
        </b-table-column>

        <b-table-column label="Assigned Educator" v-slot="props">{{
          props.row.educator ? props.row.educator.name : ''
        }}</b-table-column>
        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            @initialize-update="initializeUpdate(props.row)"
            @initialize-remove="initializeRemove(props.row)"
          />
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'assigned-classes',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Assigned Classes',
      assignedClasses: [],
      id: null,
    }
  },
  methods: {
    initializeInfo(assigned_class) {
      this.$router.push(
        `/school/${this.schoolId}/assigned_class_info/${assigned_class.schoolClass.id}`
      )
    },
    initializeUpdate(assigned_class) {
      this.$router.push(
        `/school/${this.schoolId}/assigned_class/${assigned_class.id}`
      )
    },
    initializeRemove(assigned_class) {
      this.$buefy.notification.open({
        duration: 5000,
        message: 'Not Allowed.',
        position: 'is-top',
        type: 'is-danger',
        hasIcon: true,
      })
      return
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', null)

    this.$apollo.addSmartQuery('assignedClasses', {
      query: gql`
        query AssignedClassesQuery($schoolId: Int!) {
          assignedClasses(schoolId: $schoolId) {
            id
            schoolClass {
              id
              name
            }
            numberOfStudents
            numberOfSubjects
            educator {
              id
              name
            }
          }
        }
      `,
      variables() {
        return { schoolId: this.schoolId }
      },
    })

    this.$apollo.queries.assignedClasses.refetch()
  },
}
</script>
